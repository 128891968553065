<script setup lang="ts">
import {SearchIndex} from "algoliasearch";
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Navigation } from 'vue3-carousel';

const props = defineProps<{
    categoryIds: string[];
    config: any;
}>();

const {t} = useI18n()
const imgWidth = ref('185');
const imgHeight = ref('185');
const indexName = useRuntimeConfig().public.algoliaDev.categoryIndexName;
const algolia = useAlgoliaRef();
const index = algolia.initIndex(indexName) as SearchIndex;
const subcategories = ref([]);
const subcategoryCarousel = ref(null);
const isSlider = ref(true);

onBeforeMount(async () => {
    subcategories.value = await index.getObjects(props.categoryIds).then(result => {
        const results = result.results;
        return results.filter((category) => category !== null);
    });
});

function getRelativeUrl(absoluteUrl) {
    const url = new URL(absoluteUrl);
    return url.pathname;
}

const title = props.config.title?.value ?? t('listing.popularCategories')

watch(() => subcategoryCarousel.value?.data.config.itemsToShow, (value) => {
    isSlider.value = (subcategories.value.length > value);
    if (value < 4) {
        imgWidth.value = '125';
    } else {
        imgWidth.value = '185';
    }
    imgHeight.value = imgWidth.value;
});
</script>
<template>
    <div class="carousel-element container pt-6">
        <div v-if="subcategories.length > 0" class="pt-2">
            <h4 class="ml-5 mb-5 font-semibold text-[22px]">
                {{ title }}
            </h4>
            <carousel
                :items-to-show="2"
                :items-to-scroll="1"
                :wrap-around="isSlider"
                :mouse-drag="isSlider"
                :touch-drag="isSlider"
                :breakpoints="props.config.breakpoints"
                snap-align="start"
                ref="subcategoryCarousel"
            >
                <slide v-for="subcategory in subcategories" :key="subcategory.objectID">
                    <div class="carousel__item w-full h-full">
                        <a :href="getRelativeUrl(subcategory.url)" class="flex flex-col items-center">
                            <NuxtImg
                                :src="subcategory.media.url"
                                :alt="subcategory.name"
                                :width="imgWidth"
                                :height="imgHeight"
                                class="img-fluid"
                                loading="lazy"
                            />
                            <p class="text-center text-[19px] font-semibold">{{subcategory.name}}</p>
                        </a>
                    </div>
                </slide>
                <template #addons>
                    <template v-if="isSlider">
                        <navigation />
                    </template>
                </template>
            </carousel>
        </div>
    </div>
</template>
